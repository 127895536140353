<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="debit_bank_transfers"
                          columnsPrefix="bank_transfers.column."
                          base-table="bank_transactions"
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.menu.account-book.bank-transfers-debit"
                          :actions-list="getActions()"
                >
                    <template v-slot:top-actions>
                        <b-form-checkbox
                            class="ml-2"
                            v-model="includeAssigned"
                            switch
                            value="1"
                            unchecked-value="0"
                            @change="includeAssignedChanged"
                        >{{ $t('bank.label.include_assigned') }}
                        </b-form-checkbox>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="createIncomingInvoice(row.item.id)"
                                v-if="!row.item.assigned"
                            >
                                {{ $t('bank.actions.create_incoming_invoice') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!row.item.assigned"
                                @click="$root.$children[0].openModal('assign-incoming-invoice-modal', {id:row.item.id, transfer:row.item.transfer_data}, refreshTable)"
                            >
                                {{ $t('bank.actions.assign_incoming_invoice') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!row.item.incoming_invoice_id"
                                @click="$root.$children[0].openModal('assign-incoming-invoice-modal', {id:row.item.id, transfer:row.item.transfer_data, multiple:true}, refreshTable)"
                            >
                                {{ $t('bank.actions.multi_assign_incoming_invoice') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="row.item.assigned"
                                @click="unlink(row.item.id)"
                            >
                                {{ $t('bank.actions.unlink') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!row.item.assigned"
                                @click="deleteTransfer(row.item.id)"
                            >
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.button.delete') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import moment from "moment";

export default {
    name: 'BankTransfers',
    mixins: [commonTable],
    data() {
        return {
            tableOptions: {
                sortBy: 'date_time',
                sortDesc: true,
            },
            includeAssigned: false,
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('BankTransfers', ['getTransfersDebitTable', 'getTransfer']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'BankTransfers/fetchDebitTransfers', 'getTransfersDebitTable', {}, (tableData) => {
                if (tableData.params.filter.include_incoming_assigned) {
                    this.includeAssigned = tableData.params.filter.include_incoming_assigned
                }
            });
        },
        ctxAdditionalParams() {
            return {
                'include_incoming_assigned':  this.includeAssigned
            }
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.import'),
                    icon: 'download',
                    click: () => {
                        this.$root.$children[0].openModal('import-modal', {type: 'debitBankTransfers'}, this.refreshTable, {width: '800px'})
                    }
                },
                {
                    title: this.$t('bank.button.sync_transactions'),
                    icon: 'sync',
                    click: () => {
                        this.$root.$children[0].openModal('bank-transfers', {}, null, {width: '800px'})
                    }
                },
                {
                    title: this.$t('bank.button.create_manual_transaction'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('manual-bank-transfer-modal', {}, this.refreshTable, {width: '800px'})
                    }
                },
                {
                    title: this.$t('bank.button.transaction_from_incoming_invoice'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('transaction-from-incoming-modal', {}, this.refreshTable)
                    }
                },
                {
                    title: this.$t('bank.button.delete'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'trash',
                    click: () => {
                        this.$store.dispatch('BankTransfers/deleteDebit', this.getSelectedData('bank_transfers'))
                            .then((response) => {
                                this.$root.showMessage(
                                    this.$t('common.form.title.success'),
                                    this.$t('common.toasts.deleted'),
                                    'success'
                                )
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    }
                }
            ]
        },
        createIncomingInvoice(id) {
            this.$store.dispatch('BankTransfers/fetchTransfer', {id}).then(() => {
                let transfer = this.getTransfer()
                let description = ''
                Object.keys(transfer.json).forEach(key => {
                    description += key + ': ' + transfer.json[key] + "\n\r"
                })
                this.$root.$children[0].openModal('incoming-invoices-modal', {
                    preview: {
                        "brutto_amount": transfer.amount,
                        "invoice_date_timestamp": transfer.date_time,
                        "payment_date": moment(transfer.date_time).format('DD.MM.YYYY'),
                        "leistung": description,
                        "type": "eingang",
                        "createdAt": moment(transfer.date_time).format('DD.MM.YYYY'),
                        "vat": null,
                        "transfer_id": id,
                    }
                }, this.refreshTable)
            })
        },
        includeAssignedChanged(val) {
            this.$set(this.filter, 'include_incoming_assigned', val)
            this.$set(this.filter, 'filterChanged', true)
        },
        deleteTransfer(id) {
            this.$store.dispatch('BankTransfers/delete', id)
                .finally(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.deleted'),
                        'success'
                    )
                    this.refreshTable()
                })
        },
        unlink(id) {
            this.$store.dispatch('BankTransfers/unlinkDebit', {
                id: id,
                incoming_invoice_id: null
            }).finally(() => {
                this.refreshTable()
            })
        },
    },
}
</script>